import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import Quill from 'quill';
import Delta from 'quill-delta';
import { AlignClass, AlignStyle } from 'quill/formats/align';

enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

Quill.register(AlignStyle, true);
