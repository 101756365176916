import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { A, BACKSPACE, DELETE, DOWN_ARROW, SPACE, UP_ARROW } from '@angular/cdk/keycodes';
import { removeHtmlTags } from '@shared/utils';

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AppInputComponent,
      multi: true,
    },
  ],
})
export class AppInputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('data', { static: true }) data: ElementRef;

  @Input() type: 'text' | 'email' | 'tel' | 'password' | 'number' | 'date' | 'time' | 'datetime-local' | 'currency' =
    'text';

  @Input() size: 'large' | 'small' = 'large';

  /**
   * Input contents
   *
   * @required
   */
  @Input() label = '';

  @Input() subLabel = '';

  /**
   * Input placeholder.
   *
   * @required
   */
  @Input() placeholder = 'Placeholder';

  /**
   * Input icon.
   * url or path, for example ./assets/icons/search.svg
   */
  @Input() icon = '';

  @Input() iconClass = '';

  @Input() showIcon = true;

  @Input() showStarAlways = false;

  @Input() classes = '';

  @Input() labelClasses = '';

  @Input() subLabelClasses = '';

  /**
   * Input value
   */
  @Input() value = '';
  /**
   * Can use NgControl (in datepicker we can't use this)
   */
  @Input() useNgControl = true;

  @Input() deleteOnly: boolean = false;

  @Input() isDeleteValueDisabled: boolean;

  @Input() isValid: boolean = true;

  /**
   * Input disabled property
   */
  @Input() disabled = false;

  @Input() borderRadius = '4px';

  @Input() showStarRequired = true;

  @Input() validationMessage: string;
  /**
   * Optional click handler
   */
  @Output() clickEvent = new EventEmitter<Event>();

  /**
   * Optional Change And Input Events handler
   */
  @Output() changeEvent = new EventEmitter<any>();

  /**
   * Optional Focus Event handler
   */
  @Output() focusEvent = new EventEmitter<any>();

  @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();

  public ngControl: AbstractControl | null;
  hasFocus = false;

  ngAfterViewInit(): void {
    if (this.useNgControl) {
      this.getControl();
    }
  }

  private getControl(): void {
    setTimeout(() => {
      this.ngControl = this.injector.get(NgControl)?.control;
    });
  }

  constructor(private injector: Injector) {}

  public getInputWidth(): number {
    return this.data.nativeElement?.clientWidth;
  }

  public get inputClasses(): string {
    const classes = {
      large: 'input-large',
      small: 'input-small',
    };

    const currency = this.type === 'currency' ? 'currency' : '';

    return `input ${classes[this.size]} ${this.classes} ${currency}`;
  }

  private onChange: (value: string) => void = (value: string): void => {
    const newValue = removeHtmlTags(value);
    this.value = newValue.trim()?.length > 0 ? newValue : newValue.trim();
    this.changeEvent.next(this.value);
  };
  private onInput: any = () => {};
  private onTouched: any = () => {};

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnInput(fn: any): void {
    this.onInput = fn;
    this.changeEvent.emit(fn.target.value);
  }

  registerFocusEvent(event: any): void {
    this.hasFocus = true;
    this.focusEvent.emit(event);
  }

  registerClickEvent(event: any): void {
    this.clickEvent.emit(event);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  input($event: Event): void {
    const inputElement = $event.target as HTMLInputElement;
    const newValue = removeHtmlTags(inputElement.value);
    this.value = newValue.trim()?.length > 0 ? newValue : newValue.trim();
    inputElement.value = this.value; // Ensure the input element's value is updated
    this.onChange(this.value);
  }

  keyDown($event: KeyboardEvent) {
    const isDropKey = [DELETE, BACKSPACE, SPACE].includes($event.keyCode);
    const isArrowsKey = [UP_ARROW, DOWN_ARROW].includes($event.keyCode);

    if (
      $event.keyCode === A &&
      $event.type === 'keydown' &&
      !!$event.srcElement &&
      ($event.ctrlKey || $event.metaKey)
    ) {
      ($event.srcElement as HTMLInputElement).select();
    }

    if (this.type === 'number' && isArrowsKey) {
      $event.preventDefault();
    }

    if (this.isDeleteValueDisabled) {
      $event.preventDefault();
    }

    if (this.deleteOnly) {
      $event.preventDefault();
      if (isDropKey) {
        this.value = '';
        this.onChange('');
      }
    }
  }

  markAsTouched(): void {
    this.hasFocus = false;
    if (this.ngControl?.invalid) {
      this.ngControl?.markAsTouched();
    }
  }

  public isInvalidControl(): boolean {
    if (!this.useNgControl || !this.ngControl) {
      return !this.isValid;
    }
    return !this.ngControl.valid && this.ngControl.touched && !this.ngControl.disabled;
  }

  public hasInputIcon(): boolean {
    return !!this.icon && !!this.showIcon && this.type !== 'currency';
  }

  public onWheel(): void {
    if (this.type === 'number') {
      this.data.nativeElement.blur();
    }
  }
}
